import { countryCodeToHubspotId } from 'cms-suppliers/lib/premium/utils/hubspot/get-hubspot-id';
import type { ISupplier_Type } from '@bridebook/models/source/models/Suppliers.types';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';

// Helpers
const { GB, DE, FR, IE, CA, AU, US } = CountryCodes;

export const countriesWithMarketInsights = Object.values(CountryCodes).filter(
  (code) => ![DE, FR, IE].includes(code),
);

export const countriesWithRW = [GB, DE, FR, IE];

export const countriesWithSupplierRecommendations = [GB, DE];

export const countriesWithTrendingCarousel = [GB, DE, FR, IE];
export const countriesWithRecommendedCarousel = [GB, DE];
export const countriesWithSpecialOffersCarousel = [GB];
export const countriesWithWeddingFairsCarousel = [GB];
export const countriesWithRealWeddingCarousel = [GB];

export const countriesWithLateAvailabilityFilter = [GB, DE];

export const countriesWithROI = [GB];
export const countriesWithMultiCountyForVenues = [GB, DE, FR, IE];

export const countriesWithMultiCountyForSuppliers = [GB];

export const countriesWithBadges = [GB, DE, IE, FR];

export const countriesWithPowerMatch = [GB, DE, FR, IE];

export const countriesWithLostLeadModal = [GB, DE, FR, IE];

export const countriesWithSuggestionDropdown = [GB, IE, DE, FR, CA, AU];

export const countriesWithLostLeadsStats = [GB, DE, FR, IE];

export const countriesWithVideoToursBanner = [GB];
export const countriesWithVenueRecommendationsBanner = [GB];

export const countriesWithDifferentAdminArea = [FR, DE];

export const countriesWithAdvice = [
  ...gazetteer.getCountries({ monetized: true }).map((country) => country.id),
  AU,
  CA,
];

export const countriesWithSupplierPro = [GB, DE];

export const marketsWithLandingPages = gazetteer
  .getMarkets({ blocked: false })
  .filter((market) => market.getLocaleFlags()?.public && market.hasAnyDirectory);

export const countriesWithHubspot = [GB, ...Object.keys(countryCodeToHubspotId)];

/**
 * Countries explicitly excluded from the signup dropdown.
 */
export const countriesExcludedFromSignup = [US];

/**
 * Force searching with curated suppliers list filter turned on by default
 */
export const countriesWithForcedCuratedListSearch = [US];

/**
 * Countries with required phone number
 */
export const countriesWithRequiredPhoneNumber = [GB, FR];

// Countries with county field visible
export const countriesWithAdminArea2Input = [GB, IE];

// Countries with several admin areas 1
// E.g. GB has several admin areas 1: Wales, Scotland, Northern Ireland and England
export const countriesWithSeveralAdminArea1 = [GB];

/**
 * Countries with reveal email being available for VP only
 */
export const countriesWithVPEmailReveal = [GB, IE];

export const countriesWithInclusivitySectionHidden = [FR];

type CountriesWithTrustedSupplierIndicator = Partial<
  Record<CountryCodes, Partial<Record<ISupplier_Type, boolean>>>
>;

export const countriesWithTrustedSupplierIndicator: CountriesWithTrustedSupplierIndicator = {
  [GB]: { venue: true },
};
