export enum HubspotIds {
  Sarah = 'sarah565',
  DeanWatson = 'dean-watson',
  DylanPackham = 'dylan-packham',
  LisaChallinor = 'lchallinor',
  JakeHucklesby = 'jake-hucklesby',
  JoeKinnear = 'joe-kinnear',
  KaneNelhams = 'kane12',
  KatieGallagher = 'katie-gallagher1',
  SiobhanMullins = 'siobhan-mullins',
  JulieRudkin = 'julie-rudkin',
  JasmineBoatswain = 'jasmine-boatswain',
  OscarAllsup = 'oscar-allsup',
  Test = 'beth13',
  AnisBerthold = 'anis-berthold',
  RossBlake = 'ross145',
  ZaraMughal = 'zara-mughal',
  Simon = 'simon-devey',
  Benji = 'benjamin-andrews1',
  Tevis = 'tevis-knight',
  Cameron = 'cameron-powell2',
  Katie = 'katie-jackson3',
  Maria = 'maria-saavedra2',
  Nicola = 'nicola-carrera',
  Sahil = 'skazi',
  KatH = 'katherine-horwood',
  Stefanie = 'stefanie-chung',
  Suraj = 'suraj-soroay',
  AndreasItsos = 'andreas-itsos',
  StefanieWeigang = 'stefanie-weigang',
  JosefStratmann = 'josef-stratmann',
}

export type TSalesAssignments = Record<
  HubspotIds,
  {
    id: HubspotIds;
    url: string;
    regionsAssigned: string[];
  }
>;
