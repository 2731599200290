import { HubspotIds, TSalesAssignments } from './types';

export const salesAssignments: TSalesAssignments = {
  [HubspotIds.Sarah]: {
    id: HubspotIds.Sarah,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: ['York'],
  },
  [HubspotIds.Benji]: {
    id: HubspotIds.Benji,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: ['Greater Manchester', 'Buckinghamshire'],
  },
  [HubspotIds.Tevis]: {
    id: HubspotIds.Tevis,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: ['Greater London', 'City of London'],
  },
  [HubspotIds.Cameron]: {
    id: HubspotIds.Cameron,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: ['Greater London', 'City of London', 'Slough', 'Hertfordshire'],
  },
  [HubspotIds.LisaChallinor]: {
    id: HubspotIds.LisaChallinor,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [
      'Cheshire',
      'Cheshire West and Chester',
      'Cumbria',
      'Lancashire',
      'Merseyside',
    ],
  },
  [HubspotIds.Katie]: {
    id: HubspotIds.Katie,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [
      'Essex',
      'Gloucestershire',
      'South Gloucestershire',
      'East Sussex',
      'Kent',
      'Medway',
    ],
  },
  [HubspotIds.Maria]: {
    id: HubspotIds.Maria,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [
      'Aberdeen City',
      'Aberdeenshire',
      'Angus',
      'Argyll and Bute',
      'City of Edinburgh',
      'Clackmannanshire',
      'Dumfries and Galloway',
      'Dundee City',
      'Dundee City Council',
      'East Ayrshire',
      'East Dunbartonshire',
      'East Lothian',
      'East Lothian Council',
      'East Renfrewshire',
      'Edinburgh',
      'Falkirk',
      'Fife',
      'Glasgow City',
      'Glasgow',
      'Highland',
      'Inverclyde',
      'Midlothian',
      'Moray',
      'Na h-Eileanan an Iar',
      'North Ayrshire',
      'Orkney',
      'Perth and Kinross',
      'Renfrewshire',
      'Scottish Borders',
      'Shetland Islands',
      'South Ayrshire',
      'Stirling',
      'West Dunbartonshire',
      'West Lothian',
      'Western Isles',
      'North Lanarkshire',
    ],
  },
  [HubspotIds.Nicola]: {
    id: HubspotIds.Nicola,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [
      'Herefordshire',
      'Derbyshire',
      'Northumberland',
      'Shropshire',
      'Staffordshire',
      'West Midlands',
    ],
  },
  [HubspotIds.Sahil]: {
    id: HubspotIds.Sahil,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [
      'County Durham',
      'Durham',
      'East Riding of Yorkshire',
      'North Yorkshire',
      'Sheffield',
      'South Lanarkshire',
      'South Yorkshire',
      'Stockton-on-Tees',
      'Tyne and Wear',
      'West Yorkshire',
    ],
  },
  [HubspotIds.KatH]: {
    id: HubspotIds.KatH,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [
      'Oxfordshire',
      'Warwickshire',
      'West Sussex',
      'Somerset',
      'Berkshire',
      'Dorset',
      'Hampshire',
      'Wiltshire',
      'Isle of Wight',
    ],
  },
  [HubspotIds.Stefanie]: {
    id: HubspotIds.Stefanie,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [
      'Wrexham',
      'Antrim',
      'Belfast',
      'County Antrim',
      'County Armagh',
      'County Down',
      'County Fermanagh',
      'County Londonderry',
      'County Tyrone',
      'Blaenau Gwent',
      'Bridgend',
      'Caerphilly',
      'Cardiff',
      'Carmarthenshire',
      'Ceredigion',
      'Conwy',
      'Denbighshire',
      'Flintshire',
      'Gwynedd',
      'Isle of Anglesey',
      'Merthyr Tydfil',
      'Monmouthshire',
      'Neath Port Talbot',
      'Newport',
      'Pembrokeshire',
      'Powys',
      'Rhondda Cynon Taf',
      'Swansea',
      'Torfaen',
      'Vale of Glamorgan',
    ],
  },
  [HubspotIds.Suraj]: {
    id: HubspotIds.Suraj,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [
      'Leicestershire',
      'Northamptonshire',
      'Rutland',
      'Worcestershire',
      'Bedfordshire',
      'Bedford',
      'Cambridgeshire',
      'Norfolk',
      'Suffolk',
      'Lincolnshire',
    ],
  },
  [HubspotIds.DeanWatson]: {
    id: HubspotIds.DeanWatson,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: ['Derbyshire', 'Derby', 'Blackburn with Darwen'],
  },
  [HubspotIds.DylanPackham]: {
    id: HubspotIds.DylanPackham,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [
      'Ireland',
      'Northern Ireland',
      'Armagh',
      'Derry',
      'Co Donegal',
      'Cork',
      'Fermanagh',
      'Kerry',
      'Meath',
      'Moyle',
      'Sligo',
      'Tipperary',
      'Wicklow',
      'West Berkshire',
      'The City of Brighton and Hove',
      'Brighton and Hove',
    ],
  },
  [HubspotIds.JakeHucklesby]: {
    id: HubspotIds.JakeHucklesby,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: ['Devon', 'Cornwall'],
  },
  [HubspotIds.JoeKinnear]: {
    id: HubspotIds.JoeKinnear,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [],
  },
  [HubspotIds.KaneNelhams]: {
    id: HubspotIds.KaneNelhams,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [
      'Nottinghamshire',
      'Leicester',
      'Midlands',
      'Midlands',
      'Cambs',
      'Herts',
      'Bournemouth',
      'Windsor and Maidenhead',
      'Bracknell Forest',
      'Portsmouth',
      'Falmouth',
      'Plymouth',
    ],
  },
  [HubspotIds.KatieGallagher]: {
    id: HubspotIds.KatieGallagher,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [
      'North Lincolnshire',
      'Bath and North East Somerset',
      'North Somerset',
      'England',
      'Argyll and Bute Council',
      'Scotland',
      'East Ayrshire Council',
      'South Ayrshire Council',
      'East Lothian',
      'Highland',
      'Highland Council',
      'Highlands',
      'Isle of Harris',
      'Scotland',
      'Surrey',
    ],
  },
  [HubspotIds.JasmineBoatswain]: {
    id: HubspotIds.JasmineBoatswain,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: ['Wales', 'Bridgend County Borough', 'Rhondda Cynon Taff'],
  },
  [HubspotIds.SiobhanMullins]: {
    id: HubspotIds.SiobhanMullins,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: ['Bristol', 'City of Bristol', 'Bristol City', 'County of Bristol'],
  },
  [HubspotIds.JulieRudkin]: {
    id: HubspotIds.JulieRudkin,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [],
  },
  [HubspotIds.OscarAllsup]: {
    id: HubspotIds.OscarAllsup,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [],
  },
  [HubspotIds.Test]: {
    id: HubspotIds.Test,
    url: '/testcms',
    regionsAssigned: ['Test'],
  },
  [HubspotIds.AnisBerthold]: {
    id: HubspotIds.AnisBerthold,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [],
  },
  [HubspotIds.Simon]: {
    id: HubspotIds.Simon,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [],
  },
  [HubspotIds.RossBlake]: {
    id: HubspotIds.RossBlake,
    url: '/meet-with-ross-blake',
    regionsAssigned: [],
  },
  [HubspotIds.ZaraMughal]: {
    id: HubspotIds.ZaraMughal,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [],
  },
  [HubspotIds.AndreasItsos]: {
    id: HubspotIds.AndreasItsos,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [],
  },
  [HubspotIds.StefanieWeigang]: {
    id: HubspotIds.StefanieWeigang,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [],
  },
  [HubspotIds.JosefStratmann]: {
    id: HubspotIds.JosefStratmann,
    url: 'https://meetings.hubspot.com/',
    regionsAssigned: [],
  },
};
