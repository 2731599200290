import dynamic from 'next/dynamic';
import { FunctionComponent, SyntheticEvent } from 'react';
import { IIconProps } from 'src/types';
import { FelaCSS } from '../components/fela/flowtypes';
import { IStylingProps, TColor } from '../themes/types';

export interface IconProps extends IStylingProps {
  id?: string;
  color?: TColor;
  color2?: TColor;
  backgroundColor?: string;
  width?: number;
  height?: number;
  style?: FelaCSS;
  onClick?(e: SyntheticEvent<SVGElement>): void;
}

/* ############################################################################
 *  EXPORTS
 * ######################################################################### */

export type IconComp = FunctionComponent<IconProps>;

export const IconApprove = dynamic<IconProps>(() => import('../components/svg/icon-approve'), {
  loading: () => null,
  ssr: false,
});

export const IconGiftlist = dynamic<IconProps>(() => import('../components/svg/icon-giftlist'), {
  loading: () => null,
  ssr: false,
});

export const IconArrow = dynamic<IconProps>(() => import('../components/svg/icon-arrow'), {
  loading: () => null,
  ssr: false,
});

export const IconAwardCup = dynamic<IconProps>(() => import('../components/svg/icon-award-cup'), {
  loading: () => null,
  ssr: false,
});

export const IconAwardQuote = dynamic<IconProps>(
  () => import('../components/svg/icon-award-quote'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconAwardBadge = dynamic<IconProps>(
  () => import('../components/svg/icon-award-badge'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconBb = dynamic<IconProps>(() => import('../components/svg/icon-bb'), {
  loading: () => null,
  ssr: false,
});

export const IconAccommodation = dynamic<IconProps>(
  () => import('../components/svg/icon-accommodation'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconBookFilled = dynamic<IconProps>(
  () => import('../components/svg/icon-book-filled'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconBook = dynamic<IconProps>(() => import('../components/svg/icon-book'), {
  loading: () => null,
  ssr: false,
});

export const IconBookmark = dynamic<IIconProps>(() => import('../components/svg/icon-bookmark'), {
  loading: () => null,
  ssr: false,
});

export const IconBookmarkFilled = dynamic<IconProps>(
  () => import('../components/svg/icon-bookmark-filled'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconBudget = dynamic<IconProps>(() => import('../components/svg/icon-budget'), {
  loading: () => null,
  ssr: false,
});

export const IconBurger = dynamic<IconProps>(() => import('../components/svg/icon-burger'), {
  loading: () => null,
  ssr: false,
});

export const IconBurgerCircled = dynamic<IconProps>(
  () => import('../components/svg/icon-burger-circled'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconCalendar = dynamic<IIconProps>(() => import('../components/svg/icon-calendar'), {
  loading: () => null,
  ssr: false,
});

export const IconCalendarHeart = dynamic<IIconProps>(
  () => import('../components/svg/icon-calendar-heart'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconChecklist = dynamic<IconProps>(() => import('../components/svg/icon-checklist'), {
  loading: () => null,
  ssr: false,
});

export const IconChecklistFilled = dynamic<IconProps>(
  () => import('../components/svg/icon-checklist-filled'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconChevron = dynamic<IIconProps>(() => import('../components/svg/icon-chevron'), {
  loading: () => null,
  ssr: false,
});

export const IconCross = dynamic<IconProps>(() => import('../components/svg/icon-cross'), {
  loading: () => null,
  ssr: false,
});

export const IconCrossBold = dynamic<IconProps>(() => import('../components/svg/icon-cross-bold'), {
  loading: () => null,
  ssr: false,
});

export const IconExternalLink = dynamic<IconProps>(
  () => import('../components/svg/icon-external-link'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconExclusiveUse = dynamic<IconProps>(
  () => import('../components/svg/icon-exclusive-use'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconContact = dynamic<IconProps>(() => import('../components/svg/icon-contact'), {
  loading: () => null,
  ssr: false,
});

export const IconArchive = dynamic<IconProps>(() => import('../components/svg/icon-archive'), {
  loading: () => null,
  ssr: false,
});

export const IconUnarchive = dynamic<IconProps>(() => import('../components/svg/icon-unarchive'), {
  loading: () => null,
  ssr: false,
});

export const IconHoneymoon = dynamic<IconProps>(() => import('../components/svg/icon-honeymoon'), {
  loading: () => null,
  ssr: false,
});

export const IconExperience = dynamic<IconProps>(
  () => import('../components/svg/icon-experience'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconFacebook = dynamic<IconProps>(() => import('../components/svg/icon-facebook'), {
  loading: () => null,
  ssr: false,
});

export const IconGuest = dynamic<IconProps>(() => import('../components/svg/icon-guest'), {
  loading: () => null,
  ssr: false,
});

export const IconHomeDashboard = dynamic<IconProps>(
  () => import('../components/svg/icon-home-dashboard'),
  {
    loading: () => null,
    ssr: false,
  },
);

/**
 * @deprecated
 */
export const IconHome = dynamic<IconProps>(() => import('../components/svg/icon-home'), {
  loading: () => null,
  ssr: false,
});

export const IconHomeMain = dynamic<IconProps>(() => import('../components/svg/icon-home-main'), {
  loading: () => null,
  ssr: false,
});

export const IconHomeFilled = dynamic<IconProps>(
  () => import('../components/svg/icon-home-filled'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconWeddingLicence = dynamic<IconProps>(
  () => import('../components/svg/icon-wedding-licence'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconInstagram = dynamic<IconProps>(() => import('../components/svg/icon-instagram'), {
  loading: () => null,
  ssr: false,
});

export const IconWeb = dynamic<IconProps>(() => import('../components/svg/icon-web'), {
  loading: () => null,
  ssr: false,
});

export const IconLinkedin = dynamic<IconProps>(() => import('../components/svg/icon-linkedin'), {
  loading: () => null,
  ssr: false,
});

export const IconLocation = dynamic<IconProps>(() => import('../components/svg/icon-location'), {
  loading: () => null,
  ssr: false,
});

export const IconLocationFilled = dynamic<IconProps>(
  () => import('../components/svg/icon-location-filled'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconLock = dynamic<IconProps>(() => import('../components/svg/icon-lock'), {
  loading: () => null,
  ssr: false,
});

export const IconLogout = dynamic<IconProps>(() => import('../components/svg/icon-logout'), {
  loading: () => null,
  ssr: false,
});

export const IconLoveLetter = dynamic<IconProps>(
  () => import('../components/svg/icon-love-letter'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconLoveLetterBold = dynamic<IconProps>(
  () => import('../components/svg/icon-love-letter-bold'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconNote = dynamic<IconProps>(() => import('../components/svg/icon-note'), {
  loading: () => null,
  ssr: false,
});

export const IconPackage = dynamic<IconProps>(() => import('../components/svg/icon-package'), {
  loading: () => null,
  ssr: false,
});

export const IconPaperplane = dynamic<IconProps>(
  () => import('../components/svg/icon-paperplane'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconEdit = dynamic<IconProps>(() => import('../components/svg/icon-edit'), {
  loading: () => null,
  ssr: false,
});

export const IconPhone = dynamic<IconProps>(() => import('../components/svg/icon-phone'), {
  loading: () => null,
  ssr: false,
});

export const IconCamera = dynamic<IconProps>(() => import('../components/svg/icon-camera'), {
  loading: () => null,
  ssr: false,
});

export const IconImg = dynamic<IconProps>(() => import('../components/svg/icon-img'), {
  loading: () => null,
  ssr: false,
});

export const IconPound = dynamic<IconProps>(() => import('../components/svg/icon-pound'), {
  loading: () => null,
  ssr: false,
});

export const IconRing = dynamic<IconProps>(() => import('../components/svg/icon-ring'), {
  loading: () => null,
  ssr: false,
});

export const IconSearch = dynamic<IconProps>(() => import('../components/svg/icon-search'), {
  loading: () => null,
  ssr: false,
});

export const IconSearchHeart = dynamic<IconProps>(
  () => import('../components/svg/icon-search-heart'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconSearchWrong = dynamic<IconProps>(
  () => import('../components/svg/icon-search-wrong'),
  {
    loading: () => null,
    ssr: false,
  },
);

/**
 * @deprecated
 */
export const IconSettingDeprecated = dynamic<IconProps>(
  () => import('../components/svg/icon-setting-deprecated'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconSettingFilled = dynamic<IconProps>(
  () => import('../components/svg/icon-setting-filled'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconShortlistFilled = dynamic<IconProps>(
  () => import('../components/svg/icon-shortlist-filled'),
  {
    loading: () => null,
    ssr: false,
  },
);

/**
 * @deprecated
 */
export const IconShortlist = dynamic<IconProps>(() => import('../components/svg/icon-shortlist'), {
  loading: () => null,
  ssr: false,
});

export const IconReview = dynamic<IconProps>(() => import('../components/svg/icon-review'), {
  loading: () => null,
  ssr: false,
});

export const IconReviewFilled = dynamic<IconProps>(
  () => import('../components/svg/icon-review-filled'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconTestimonials = dynamic<IconProps>(
  () => import('../components/svg/icon-testimonials'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconTick = dynamic<IconProps>(() => import('../components/svg/icon-tick'), {
  loading: () => null,
  ssr: false,
});

export const IconTickBold = dynamic<IconProps>(() => import('../components/svg/icon-tick-bold'), {
  loading: () => null,
  ssr: false,
});

export const IconTickRound = dynamic<IconProps>(() => import('../components/svg/icon-tick-round'), {
  loading: () => null,
  ssr: false,
});

export const IconTwitter = dynamic<IconProps>(() => import('../components/svg/icon-twitter'), {
  loading: () => null,
  ssr: false,
});

export const IconVenue = dynamic<IconProps>(() => import('../components/svg/icon-venue'), {
  loading: () => null,
  ssr: false,
});

export const IconVenueColorful = dynamic<IconProps>(
  () => import('../components/svg/icon-venue-colorful'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconVimeo = dynamic<IconProps>(() => import('../components/svg/icon-vimeo'), {
  loading: () => null,
  ssr: false,
});

export const IconVideo = dynamic<IconProps>(() => import('../components/svg/icon-video'), {
  loading: () => null,
  ssr: false,
});

export const IconWeddingPlanningTools = dynamic<IconProps>(
  () => import('../components/svg/icon-wedding-planning-tools'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconWeddingDate = dynamic<IconProps>(
  () => import('../components/svg/icon-wedding-date'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconFilter = dynamic<IconProps>(() => import('../components/svg/icon-filter'), {
  loading: () => null,
  ssr: false,
});

export const IconAdd = dynamic<IIconProps>(() => import('../components/svg/icon-add'), {
  loading: () => null,
  ssr: false,
});

export const IconPlay = dynamic<IconProps>(() => import('../components/svg/icon-play'), {
  loading: () => null,
  ssr: false,
});

export const IconPhotographer = dynamic<IconProps>(
  () => import('../components/svg/icon-photographer'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconWarningFilled = dynamic<IconProps>(
  () => import('../components/svg/icon-warning-filled'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconWarning = dynamic<IconProps>(() => import('../components/svg/icon-warning'), {
  loading: () => null,
  ssr: false,
});

export const IconWelcome = dynamic<IconProps>(() => import('../components/svg/icon-welcome'), {
  loading: () => null,
  ssr: false,
});

export const IconConfeti = dynamic<IconProps>(() => import('../components/svg/icon-confeti'), {
  loading: () => null,
  ssr: false,
});

export const IconRepeat = dynamic<IconProps>(() => import('../components/svg/icon-repeat'), {
  loading: () => null,
  ssr: false,
});

export const IconAutumn = dynamic<IconProps>(() => import('../components/svg/icon-autumn'), {
  loading: () => null,
  ssr: false,
});

export const IconWinter = dynamic<IconProps>(() => import('../components/svg/icon-winter'), {
  loading: () => null,
  ssr: false,
});

export const IconSpring = dynamic<IconProps>(() => import('../components/svg/icon-spring'), {
  loading: () => null,
  ssr: false,
});

export const IconTrash = dynamic<IIconProps>(() => import('../components/svg/icon-trash'), {
  loading: () => null,
  ssr: false,
});

export const IconQuick = dynamic<IIconProps>(() => import('../components/svg/icon-quick'), {
  loading: () => null,
  ssr: false,
});

export const IconWeddingAdditional = dynamic<IconProps>(
  () => import('../components/svg/icon-wedding-additional'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconSummer = dynamic<IconProps>(() => import('../components/svg/icon-summer'), {
  loading: () => null,
  ssr: false,
});

export const IconQuestionMark = dynamic<IconProps>(
  () => import('../components/svg/icon-question-mark'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconCoplanners = dynamic<IIconProps>(
  () => import('../components/svg/icon-coplanners'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconEvening = dynamic<IconProps>(() => import('../components/svg/icon-evening'), {
  loading: () => null,
  ssr: false,
});

export const IconToasting = dynamic<IconProps>(() => import('../components/svg/icon-toasting'), {
  loading: () => null,
  ssr: false,
});

export const IconEmail = dynamic<IconProps>(() => import('../components/svg/icon-email'), {
  loading: () => null,
  ssr: false,
});

export const IconGoogleColor = dynamic<IconProps>(
  () => import('../components/svg/icon-google-color'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconChat = dynamic<IconProps>(() => import('../components/svg/icon-chat'), {
  loading: () => null,
  ssr: false,
});

/**
 * @deprecated
 */
export const IconInbox = dynamic<IconProps>(() => import('../components/svg/icon-inbox'), {
  loading: () => null,
  ssr: false,
});

export const IconPinterest = dynamic<IconProps>(() => import('../components/svg/icon-pinterest'), {
  loading: () => null,
  ssr: false,
});

export const IconLink = dynamic<IconProps>(() => import('../components/svg/icon-link'), {
  loading: () => null,
  ssr: false,
});

export const IconCopy = dynamic<IconProps>(() => import('../components/svg/icon-copy'), {
  loading: () => null,
  ssr: false,
});

export const IconApple = dynamic<IconProps>(() => import('../components/svg/icon-apple'), {
  loading: () => null,
  ssr: false,
});

export const IconPlaystore = dynamic<IconProps>(() => import('../components/svg/icon-playstore'), {
  loading: () => null,
  ssr: false,
});

export const IconAddCircle = dynamic<IconProps>(() => import('../components/svg/icon-add-circle'), {
  loading: () => null,
  ssr: false,
});

export const IconSearchFilled = dynamic<IconProps>(
  () => import('../components/svg/icon-search-filled'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconSearchHeartFilled = dynamic<IconProps>(
  () => import('../components/svg/icon-search-heart-filled'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconGuestFilled = dynamic<IconProps>(
  () => import('../components/svg/icon-guest-filled'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconBudgetFilled = dynamic<IconProps>(
  () => import('../components/svg/icon-budget-filled'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconClock = dynamic<IconProps>(() => import('../components/svg/icon-clock'), {
  loading: () => null,
  ssr: false,
});

export const IconDress = dynamic<IconProps>(() => import('../components/svg/icon-dress'), {
  loading: () => null,
  ssr: false,
});

export const IconBeauty = dynamic<IconProps>(() => import('../components/svg/icon-beauty'), {
  loading: () => null,
  ssr: false,
});

export const IconMusic = dynamic<IconProps>(() => import('../components/svg/icon-music'), {
  loading: () => null,
  ssr: false,
});

export const IconCake = dynamic<IconProps>(() => import('../components/svg/icon-cake'), {
  loading: () => null,
  ssr: false,
});

export const IconMenswear = dynamic<IconProps>(() => import('../components/svg/icon-menswear'), {
  loading: () => null,
  ssr: false,
});

export const IconOther = dynamic<IconProps>(() => import('../components/svg/icon-other'), {
  loading: () => null,
  ssr: false,
});

export const IconMarquee = dynamic<IconProps>(() => import('../components/svg/icon-marquee'), {
  loading: () => null,
  ssr: false,
});

export const IconExport = dynamic<IconProps>(() => import('../components/svg/icon-export'), {
  loading: () => null,
  ssr: false,
});

export const IconScrapbook = dynamic<IconProps>(() => import('../components/svg/icon-scrapbook'), {
  loading: () => null,
  ssr: false,
});

export const IconScrapbookFilled = dynamic<IconProps>(
  () => import('../components/svg/icon-scrapbook-filled'),
  {
    loading: () => null,
    ssr: false,
  },
);

/* ############################################################################
 *  UNCONFIRMED
 * ######################################################################### */

export const BridebookLogo = dynamic<IconProps>(() => import('../components/svg/bridebook-logo'), {
  loading: () => null,
  ssr: false,
});

export const BudgetIconCopy = dynamic<IconProps>(
  () => import('../components/svg/budget-icon-copy'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const BudgetIcon = dynamic<IconProps>(() => import('../components/svg/budget-icon'), {
  loading: () => null,
  ssr: false,
});

export const ChecklistIconCopy = dynamic<IconProps>(
  () => import('../components/svg/checklist-icon-copy'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const ChecklistIcon = dynamic<IconProps>(() => import('../components/svg/checklist-icon'), {
  loading: () => null,
  ssr: false,
});

export const GuestlistIconCopy = dynamic<IconProps>(
  () => import('../components/svg/guestlist-icon-copy'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const GuestlistIcon = dynamic<IconProps>(() => import('../components/svg/guestlist-icon'), {
  loading: () => null,
  ssr: false,
});

export const IconCameraTripod = dynamic<IconProps>(
  () => import('../components/svg/icon-camera-tripod'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconCarouselLeft = dynamic<IconProps>(
  () => import('../components/svg/icon-carousel-left'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconCarouselRight = dynamic<IconProps>(
  () => import('../components/svg/icon-carousel-right'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconCastle = dynamic<IconProps>(() => import('../components/svg/icon-castle'), {
  loading: () => null,
  ssr: false,
});

export const IconChevronFat = dynamic<IconProps>(
  () => import('../components/svg/icon-chevron-fat'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconChevronRight = dynamic<IconProps>(
  () => import('../components/svg/icon-chevron-right'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconConfirmation = dynamic<IconProps>(
  () => import('../components/svg/icon-confirmation'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconFlowers = dynamic<IconProps>(() => import('../components/svg/icon-flowers'), {
  loading: () => null,
  ssr: false,
});

export const IconLocationMarker = dynamic<IconProps>(
  () => import('../components/svg/icon-location-marker'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconMagGlass = dynamic<IconProps>(() => import('../components/svg/icon-mag-glass'), {
  loading: () => null,
  ssr: false,
});

export const IconSwipe = dynamic<IconProps>(() => import('../components/svg/icon-swipe'), {
  loading: () => null,
  ssr: false,
});

export const MacaroniRaysLeft = dynamic<IconProps>(
  () => import('../components/svg/macaroni-rays-left'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const MacaroniRaysRight = dynamic<IconProps>(
  () => import('../components/svg/macaroni-rays-right'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const ShortlistIconCopy = dynamic<IconProps>(
  () => import('../components/svg/shortlist-icon-copy'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const ShortlistIcon = dynamic<IconProps>(() => import('../components/svg/shortlist-icon'), {
  loading: () => null,
  ssr: false,
});

export const VacationIcon = dynamic<IconProps>(() => import('../components/svg/vacation-icon'), {
  loading: () => null,
  ssr: false,
});

export const IconBookmarkFlag = dynamic<IconProps>(
  () => import('../components/svg/icon-bookmark-flag'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconScrapbookEmpty = dynamic<IconProps>(
  () => import('../components/svg/icon-scrapbook-empty'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconQuote = dynamic<IconProps>(() => import('../components/svg/icon-quote'), {
  loading: () => null,
  ssr: false,
});

export const PremiumRocket = dynamic<IconProps>(() => import('../components/svg/premium-rocket'), {
  loading: () => null,
  ssr: false,
});

export const IconFavouritesSearch = dynamic<IconProps>(
  () => import('../components/svg/icon-favourites-search'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconKiosk = dynamic<IconProps>(() => import('../components/svg/icon-kiosk'), {
  loading: () => null,
  ssr: false,
});

export const IconDownload = dynamic<IconProps>(() => import('../components/svg/icon-download'), {
  loading: () => null,
  ssr: false,
});

export const IconUrgencyCopy = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-urgency-copy'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconBbLogoSquared = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-bb-logo-squared'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconLocationShortlist = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-location-shortlist'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconMagnifier = dynamic<IconProps>(() => import('../components/svg/magnifier'), {
  loading: () => null,
  ssr: false,
});

export const IconTip = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-tip'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconEye = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-eye'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconLockClosed = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-lock-closed'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconLockClosedFilled = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-lock-closed-filled'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconLockOpened = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-lock-opened'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconLockOpenedFilled = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-lock-opened-filled'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconVideoTour = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-video-tour'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconVectorUp = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-vector-up'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconArrowUp = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-arrow-up'),
  { loading: () => null, ssr: false },
);

export const IconArrowDown = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-arrow-down'),
  { loading: () => null, ssr: false },
);

export const IconVectorDown = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-vector-down'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconWeddingsIllustration = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-weddings-illustration'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconShowcaseIllustration = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-showcase-illustration'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconCouplesIllustration = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-couples-illustration'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconSeoIllustration = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-seo-illustration'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconEnquiriesIllustration = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-enquiries-illustration'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconUpload = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-upload'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconSupplierList = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-supplier-list'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconMap = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-map'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconPremiumCrown = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-premium-crown'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconEur = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-eur'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconUsd = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-usd'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconArrowStat = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-arrow-stat'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconList = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-list'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconEnquiryEdit = dynamic<IconProps>(
  () => import('../components/svg/icon-enquiry-edit'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconReviewReply = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-review-reply'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconFavourites = dynamic<IconProps>(
  () => import('../components/svg/icon-favourites'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconSuppliers = dynamic<IconProps>(() => import('../components/svg/icon-suppliers'), {
  loading: () => null,
  ssr: false,
});

export const IconPlanning = dynamic<IconProps>(() => import('../components/svg/icon-planning'), {
  loading: () => null,
  ssr: false,
});

export const IconSettings = dynamic<IconProps>(() => import('../components/svg/icon-settings'), {
  loading: () => null,
  ssr: false,
});

export const IconMessages = dynamic<IconProps>(() => import('../components/svg/icon-messages'), {
  loading: () => null,
  ssr: false,
});

export const SwirlChecklist = dynamic<IconProps>(
  () => import('../components/svg/swirl-checklist'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const SwirlScrapbook = dynamic<IconProps>(
  () => import('../components/svg/swirl-scrapbook'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const SwirlBudget = dynamic<IconProps>(() => import('../components/svg/swirl-budget'), {
  loading: () => null,
  ssr: false,
});

export const SwirlBooked = dynamic<IconProps>(() => import('../components/svg/swirl-booked'), {
  loading: () => null,
  ssr: false,
});

export const SwirlFavourites = dynamic<IconProps>(
  () => import('../components/svg/swirl-favourites'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const SwirlGuestlist = dynamic<IconProps>(
  () => import('../components/svg/swirl-guestlist'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const SwirlAdvice = dynamic<IconProps>(() => import('../components/svg/swirl-advice'), {
  loading: () => null,
  ssr: false,
});

export const SwirlToolWidget = dynamic<IconProps>(
  () => import('../components/svg/swirl-tool-widget'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const ProgressBarMarker = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/progress-bar-marker'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const Tick = dynamic<IIconProps>(() => import('@bridebook/ui/src/components/svg/tick'), {
  loading: () => null,
  ssr: false,
});

export const IconInfoCircle = dynamic<IconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-info-circle'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconQuiz = dynamic<IconProps>(() => import('../components/svg/icon-quiz'), {
  loading: () => null,
  ssr: false,
});

export const IconCameraColors = dynamic<IconProps>(
  () => import('../components/svg/icon-camera-colors'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconSuccess = dynamic<IconProps>(() => import('../components/svg/icon-success'), {
  loading: () => null,
  ssr: false,
});

export const IconFailure = dynamic<IconProps>(() => import('../components/svg/icon-failure'), {
  loading: () => null,
  ssr: false,
});

export const IconTranslations = dynamic<IIconProps>(
  () => import('../components/svg/icon-translations'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconMail = dynamic<IIconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-mail'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconMail2 = dynamic<IIconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-mail-2'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconTasks = dynamic<IIconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-tasks'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconChart = dynamic<IIconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-chart'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconVenue2 = dynamic<IIconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-venue-2'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconKnowledge = dynamic<IIconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-knowledge'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconHelp = dynamic<IIconProps>(
  () => import('@bridebook/ui/src/components/svg/icon-help'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconStar = dynamic<IconProps>(() => import('../components/svg/icon-star'), {
  loading: () => null,
  ssr: false,
});

export const IconInformation = dynamic<IconProps>(
  () => import('../components/svg/icon-information'),
  {
    loading: () => null,
    ssr: false,
  },
);

export const IconHeartHand = dynamic<IconProps>(() => import('../components/svg/icon-heart-hand'), {
  loading: () => null,
  ssr: false,
});
